@import "./theme.scss";

@font-face {
    font-family: interReg;
    src: url('./../../assets/fonts/inter/Inter-Regular.ttf');
}
@font-face {
    font-family: interSemiBold;
    src: url('./../../assets/fonts/inter/Inter-SemiBold.ttf');
}
@font-face {
    font-family: interBold;
    src: url('./../../assets/fonts/inter/Inter-Bold.ttf');
}
@font-face {
    font-family: interExtraBold;
    src: url('./../../assets/fonts/inter/Inter-ExtraBold.ttf');
}
@font-face {
    font-family: interLight;
    src: url('./../../assets/fonts/inter/Inter-Light.ttf');
}
@font-face {
    font-family: interMedium;
    src: url('./../../assets/fonts/inter/Inter-Medium.ttf');
}
@font-face {
  font-family: interBlack;
  src: url('./../../assets/fonts/inter/Inter-Black.ttf');
}
@font-face {
  font-family: interExtraLight;
  src: url('./../../assets/fonts/inter/Inter-ExtraLight.ttf');
}
.cursor-pointer{
    cursor: pointer;
}

/* width */
::-webkit-scrollbar {
    width: 0.1px;
  }
  
  /* Track */
  ::-webkit-scrollbar-track {
    box-shadow: inset 0 0 5px $white;
    border-radius: 10px;
  }
  
  /* Handle */
  ::-webkit-scrollbar-thumb {
    background: $grey-text;
    border-radius: 10px;
  }
  
  /* Handle on hover */
  ::-webkit-scrollbar-thumb:hover {
    background: $white;
  }
  
  ::-webkit-scrollbar {
    display: none;
  }

  button:disabled {
    background-color: $grey-text !important;
    border-color: none !important;
    color: $white !important;
    cursor: default !important;
  }
  button{
    cursor: pointer;
  }
  .close{
    opacity: 1 !important;
  }
    .mat-select-panel{
      margin-top: 35px;
    }

  // .custom-slider {
  //   .ngx-slider {
  //     .ngx-slider-pointer {
  //       background-color: $brand-warning-color !important;
  //     }
  //     .ngx-slider-pointer:after {
  //       background-color: white !important;
  //     }
  //     .ngx-slider-selection {
  //       background-color: $brand-warning-color !important;
  //     }
  //   }
  //   .ngx-slider .ngx-slider-pointer:after{
  //     content: none;
  //   }
  //   .ngx-slider .ngx-slider-pointer{
  //     width: 25px !important;
  //     height: 25px !important;
  //     top: -12px !important;
  //   }
  // }
  tr.mat-row, tr.mat-footer-row{
    height: 72px !important;
  }
  .slick-dots {
    position: relative !important;
    margin-top: 15px !important;
  }

 
  @media only screen and (min-width: 1200px)  and (max-width: 1500px){
    table{
      overflow: scroll;
      width: 115% !important;
    }
  }
  
  // .faq-header{
  //   .mat-expansion-indicator::after{
  //     content: '-';
  //   }
  //   .mat-expansion-indicator::before
  // }
  .mat-dialog-container{
    // background-color: $light-grey;
    border-radius: 10px  !important;
  }
  .question-answers{
    .mat-accordion .mat-expansion-panel:last-of-type{
      border-bottom-right-radius: 0px; 
      border-bottom-left-radius: 0px; 
      border-bottom: 0.5px solid #EAEAEA;
    }
    .mat-accordion .mat-expansion-panel:first-of-type{
      border-bottom-right-radius: 0px; 
      border-bottom-left-radius: 0px; 
    }
    .mat-expansion-panel:not([class*=mat-elevation-z]){
      box-shadow: none;
    }
    mat-expansion-panel-header .mat-expansion-indicator::after {
      content: "+";
      border: 0;
      transform: none;
      background-color: $theme-secondary;
      width: 30px;
      height: 30px;
      text-align: center;
      @include text-size(17px, interSemiBold, $theme-primary , normal, 500);
    }
    
    .mat-expansion-panel-header.mat-expanded .mat-expansion-indicator::after {
      content: "-";
      @include text-size(17px, interSemiBold, $theme-primary , normal, 500);
    }
  }
  .check-fields{
    .mat-checkbox-checked.mat-accent .mat-checkbox-background {
      background-color: #024751 !important;
    }
  }
  .search-container-all{
    .mat-form-field-appearance-outline .mat-form-field-wrapper{
      margin: 0px;
    }
    .mat-form-field-wrapper{
      padding: 0px;
    }
  }
  .sfull-width-dropdown{
    .mat-select-value{
      color: $black-600 !important;
    }
  }
  input[type=number]::-webkit-inner-spin-button, 
input[type=number]::-webkit-outer-spin-button { 
  -webkit-appearance: none; 
  margin: 0; 
}
.button.slick-prev.slick-arrow{
  position: relative;
  top: 0px;
  right: 0px;
  border: 1px solid black;
}