$black : #000000;
$white : #FFFFFF;
$grey-text : #959393;
$light-grey : #C4C4C4;
$light-black : #3F3B3B;
$light-background : #EAEAEA;
$theme-primary : #024751;
$theme-secondary : #AFF8C8;
$theme-background1 : #FEEDAE;
$theme-background2 : #E2D8FD;
$theme-background3 : #F9FBFF;
$theme-background4 : #F0F5FF;
$black-600 : #605F5F;
$less-opacity-black : #121212;
$them-secondary-sidepanel : #F2C94C;
/* ---- Mixins ---- */

@mixin  text-size($size, $font-family, $color, $font-style, $weight){
    font-family: $font-family;
    font-size: $size;
    color : $color;
    font-style: $font-style;
    font-weight: $weight;
}

@mixin primary-btn($bg, $font-color, $font-size) {
    color: $font-color;
    background: $bg;
    font-size: $font-size;
    font-family: interReg;
    border: 1px solid $font-color;
    font-weight: 600;
    border-radius: 8px;
    box-shadow: -5px 5px 20px rgba(0, 0, 0, 0.1);
    cursor: pointer;
  }


/* ---------- Font-Sizes --------- */
$fontHeading1 : 80px;
$fontHeading2 : 60px;
$fontHeading3 : 40px;
$fontHeading4 : 30px;
$fontHeading5 : 24px;
$fontHeading6 : 20px;
$fontBody: 16px;
$fontCaption : 14px;
$fontSmall : 12px;
$fontTiny : 10px;
